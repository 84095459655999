@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --yellow-color: #FAB406;
  --dark-yellow: #B48000;
  --medium-gray: #333333;
  --noto-font: "Noto Sans", sans-serif;
  --bg-gray: #F5F5F5;
  --small-font: 14px;
  --face: #be9d66;
  --lowlight: #111;
  --side: #B48000;
  --side-dark: #120e08;
  --coin-size: 7rem;
  --coin-face:
}

img {
  max-width: 100%;
  height: revert-layer;
}

.btn:focus-visible {
  border-color: #B48000 !important;
}

p,
li {
  font-size: 15px;
  color: #808080;
}

h2 {
  font-size: 43px !important;
  line-height: normal !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--noto-font) !important;
}

main {
  padding-top: 82px;
}

.header-wrapper {
  position: fixed;
  background: #ffffff;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
}

.cursor-default {
  cursor: default !important;
}

.text-light-gray {
  color: #808080 !important;
}

.bg-light-gray {
  background-color: var(--bg-gray) !important;
}

.yellow-color {
  color: var(--yellow-color);
}

.medium-gray {
  color: var(--medium-gray) !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.full-height {
  height: 100vh;
}

.w-90 {
  width: 90%
}

.rounded-10 {
  border-radius: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

input[type=number].form-control::-webkit-inner-spin-button,
input[type=number].form-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}

#dropdown-basic-button {
  display: flex;
  align-items: center;
  padding: 11px 15px !important;
}


.dropdown-toggle::after {
  display: none !important;
}

#dropdown-basic-button .MuiAvatar-root {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

button.common-button,
.btn.common-button,
#dropdown-basic-button {
  background-color: var(--yellow-color);
  border: solid 1px #B48000;
  color: #000;
  font-family: var(--noto-font);
  font-weight: 600;
  font-size: var(--small-font);
  padding: 10px 30px;
  border-radius: 10px;
}

.header-wrapper button.common-button {
  padding: 12px 15px;
}

button.common-button:hover,
.btn.common-button:hover,
#dropdown-basic-button:hover {
  border-color: #B48000;
}

button.common-button.white-btn {
  background-color: #fff;
  border-color: #fff;
}

.btn.common-button.back-btn {
  background-color: #808080;
  border-color: #808080;
  color: #fff;
}

.btn.common-button.back-btn:hover {
  color: #808080;
  background-color: transparent;
}

.form-control {
  overflow: hidden;
  box-shadow: unset !important;
}

.auth-login-signup {
  background-image: url(../src/images/login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

.login-bg {
  background-image: url(../src/images/login-bg-new.png);
}

.login-btns {
  position: absolute;
  top: -27px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 64%;
}

/* banner css */
.ban_sec {
  /* background-image: url('./images/ib-3.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  position: relative;
  width: 100%;
  margin-top: 25px;
}

.ban_sec::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #00000077;
}

.works-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  position: relative;
  width: 100%;
}

.category-header {
  height: 350px;
  width: 100%;
}

.about-us-image {
  width: 600px;
  margin: 0 auto;
}

.whatis-insert {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.ban_text {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ban_text h1 {
  font-size: 45px;
  font-weight: 700;
}

.banner-buttons button {
  padding: 12px 35px;
}

.banner-buttons .buy-switch:active {
  background-color: #FAB406;
  border: 1 px solid #fab406;
}

.buy-switch:active {
  background-color: #FAB406;
}

.ban_text .common-button:hover {
  background-color: #fff;

}

.banner-buttons button.common-button {
  border-radius: 10px 0px 0px 10px;
  border-color: var(--yellow-color);
  /* pointer-events: none; */
}

.banner-buttons button.common-button.white-btn {
  border-radius: 0px 10px 10px 0px;
  border-color: #fff;
  /* pointer-events: none; */
}

button.common-button.white-btn:hover {
  background-color: var(--yellow-color);
  border: 1px solid var(--dark-yellow);
}

.banner-buttons button.common-button.buy-switch {
  border-radius: 10px 0px 0px 10px;
  background-color: #fff;
  border-color: #fff;
}

.banner-buttons button.common-button.sale-switch {
  border-radius: 0px 10px 10px 0px;
  background-color: var(--yellow-color);
  border-color: var(--yellow-color);
}

/* banner css end  */

/* category css start */
.sorting-list li a {
  background-color: var(--bg-gray);
  border: solid 1px #DDDDDD;
  color: #000;
  font-size: var(--small-font);
  font-weight: 400;
  display: block;
  line-height: normal;
}

.sorting-list li a:hover {
  background-color: #FFDD87;
}

.caterogies-card {
  width: 100%;
  height: 250px;
}

.caterogies-card>div {
  height: 100%;
}

.caterogies-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

form.location-form {
  border: solid 1px #eee;
  padding: 40px 40px;
  border-radius: 10px;
  box-shadow: 0 8px 21px rgba(225, 231, 255, 0.58);
}

.sorting-list li a.active {
  background-color: var(--yellow-color);
}

.caterogies-selection select {
  font-size: var(--small-font);
  padding: 5px;
  border-radius: 10px;
}

.price-section {
  position: absolute;
  left: 12px;
  bottom: 15px;
}

.price-section .price {
  background-color: var(--yellow-color);
  border: solid 1px #fff;
  font-size: 14px;
  padding: 0 9px;

}

.price-section .end-day-price {
  background-color: indianred;
  border: solid 1px #fff;
  font-size: 14px;
  padding: 0 9px;

}

.detail-page-listing .price-section .price {
  font-size: 12px;
  padding: 0 5px;
}

/* category css end */
.caterogies-title {
  background-color: var(--yellow-color);
  border-color: #fff;
  bottom: 25px;
  left: 15px;
}

.newsletter-section input {
  padding: 13px 30px;
}

.newsletter-section .subscriber-email {
  width: 74%;
}

.newsletter-section .subscriber-button {
  width: 25%;
}

.location {
  color: #FF0099
}

.favourite i {
  color: #0075FF;
}

.search-bar span.search-icon {
  top: 52%;
  transform: translateY(-50%);
  left: 15px;
  z-index: 1;
  width: 22px;
}

.delivery-status-popup-wrapper .modal-dialog.modal-lg .modal-content {
  width: 75% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.search-bar input {
  padding-left: 50px;
  outline: none;
  font-size: 16px;
  height: 50px;
  width: 255px;
}

/* .time-duration i:hover {
  color: #0075FF;
} */

.time-duration-inline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.auth-btns {
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  font-size: 18px;
}

.auth-btns .active {
  background: #FAB406;
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: center;
  color: #000000;
  border-radius: 100px;
  border: 1px solid #FAB406
}

.auth-btns .inactive {
  background: #ffffff;
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: center;
  color: #808080;
  border-radius: 100px;
  border: 1px solid #ffffff
}

.auth-forms {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 60px 40px;
}

.auth-forms p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.auth-login label {
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.auth-login .form-control {
  height: 43px;
  background: var(--bg-gray);
  border-radius: 10px;
  border-color: #DDDDDD;
  outline: none;
  font-size: 14px;
  box-shadow: none !important;
}

.auth-login input::placeholder,
.auth-login textarea::placeholder {
  color: #DDDDDD;
}

.auth-forms .password-input i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #808080;
  cursor: pointer;
}

.error-msg {
  font-weight: 600 !important;
  font-size: 13px !important;
}

.auth-forms .remember-text {
  color: #808080;
  font-size: 15px;
}

.opacity-zero {
  opacity: 0;
}

.auth-forms button.add-btn {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: var(--bg-gray);
  border: solid 1px #ddd;
}

/* .filter-list .form-group a{
  position:relative;
  top:1px
} */
.auth-forms button.add-btn i {
  position: absolute;
  right: 4px;
  top: 11px;
  color: #333333;
  font-size: 16px;
}

.caterogies-card .caterogies-title {
  border: solid 1px #fff;
}

.footer-top ul li {
  margin-right: 35px;
  font-weight: 500;
}

.footer-top img {
  width: 170px;
  height: 100%;
  object-fit: cover;
}

.footer-mid h5 {
  font-size: 18px;
}

.footer-mid p,
.footer-mid a {
  font-size: 14px;
}

.footer-mid h5,
.footer-bottom p {
  color: #333;
}

.footer-mid ul li:hover a,
.footer-top ul li:hover a,
.footer-mid ul li:hover {
  color: #FAB406;
}

.footer hr {
  border-top: solid 1px #ddd;
}

.footer-bottom:before {
  position: absolute;
  content: "";
  border-top: solid 1px #DDDDDD;
  right: 0;
  top: 0;
  width: 100%;
}

.footer-mid ul {
  padding-left: 25px;
}

ul.social-icon {
  list-style: none;
  padding-left: 0;
}

.footer-social-links-row ul li a:hover,
.footer-social-links-row ul li:hover {
  color: #FAB406 !important;
}

.newsletter-section p {
  font-size: 19px;
  font-weight: 400;
}

.newsletter-section input {
  font-weight: 400;
  color: #808080;
  font-size: 15px;
}

.newsletter-section input::placeholder {
  color: #808080 !important
}

.search-filter .filter-list {
  background: var(--bg-gray);
  padding: 25px 5px 10px 5px;
  border-radius: 0 0 10px 10px;
  position: relative;
}

.filter-list-section {
  height: 300px;
  overflow-y: scroll;
}

.search-filter .filter-list .list-inline {
  padding-left: 10px;
}

.search-filter .filter-heading {
  background: #DDDDDD;
  border-radius: 10px 10px 0 0;
  padding: 25px 30px;
}

.search-filter button.common-button {
  font-size: 13px;
}

.filter-list .form-group {
  display: block;
  margin-bottom: 0;
  position: relative;
  color: #333;

}

.filter-list .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.filter-list .form-group label {
  position: relative;
  cursor: pointer;
}

.filter-list .form-group label:before {
  content: '';
  -webkit-appearance: none;
  border: 1px solid #DDDDDD;
  background: #fff;
  border-radius: 2px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.search-filter-mainbox .filter-list .form-group label:before {
  margin-right: 15px;
}

.filter-list .form-group input:checked+label::before {
  background: var(--yellow-color);
  border-color: var(--yellow-color)
}

.filter-list .form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.search-filter-mainbox .filter-list .form-group input:checked+label:after {
  top: 5px;
  left: 5px;
}

.phone-input .dropdown {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.phone-input .dropdown button {
  border: none;
  padding-right: 0;
}

.phone-input .dropdown-menu {
  height: 200px;
  overflow: auto;
}

.drop-downlist .dropdown-menu {
  width: 210px;
  border: solid 1px #DDDDDD;
  top: 65px !important;
  left: -96px !important;
}

.dropdown-menu.show:before {
  content: '';
  position: absolute;
  top: 7px;
  left: 78%;
  margin-left: -15px;
  margin-top: -15px;
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid 10px #fff;
  border-left: solid 10px transparent;
  border-right: solid 11px transparent
}

.dropdown-menu.show:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ddd;
  top: -10px;
  content: '';
  left: 78%;
  margin-left: -15px;
  position: absolute;
}

.drop-downlist .MuiAvatar-root {
  border: solid 1px #fff;
}

.drop-downlist .dropdown-memulist {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
}

.phone-input input.form-control {
  padding-left: 65px;
}

.search-filter-mainbox {
  position: relative;
  left: -40px;
  background: var(--bg-gray);
  border-radius: 0px 10px 10px 0;
}

.header-wrapper nav.navbar {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 10px 0;
}

.bids-detail-section {
  background-color: #333333;
  padding: 14.5px 19px;
  justify-content: space-between;
}

.ended-bid-day-detail-section {
  background-color: red;
  padding: 14.5px 19px;
  justify-content: space-between;
  font-size: 1rem;
}

/* .ended-bid-day-detail-section p, .ended-bid-day-detail-section span{
  color: #fff;
} */

.bids-detail-section p {
  font-size: 16px;
  color: #DDDDDD;
  font-weight: 600;
}

.ended-detail-section {
  background: #F2E1B5;
  padding: 14.5px 19px;
  justify-content: space-between;
}

.auction-detail-secion p i {
  color: #FF0000
}

.detail-section ul li {
  flex: 0 0 25%;
}

.card {
  padding: 7px;
}

.card .card-body {
  padding: 10px 5px 15px;
}

.accordion-item {
  border: none;
}

.accordion-item button.accordion-button {
  background: #f5f5f5;
  border-radius: 5px !important;
  border: none;
  box-shadow: none !important;
  font-size: 15px;
  color: #333;
}

.accordion-body {
  border: solid 1px #f5f5f5;
  border-top: none;
  border-radius: 0 0 5px 5px;
  font-size: 15px;
}

.quote-section {
  background-color: var(--yellow-color);
  padding: 40px 30px;
}

.quote-section h5 {
  color: #333;
}

.quote-section .btn.common-button {
  padding: 13px 25px;
}

.biding-detail {
  border: solid 1px #DDDDDD;
}

.biding-listing {
  border-bottom: solid 1px #DDDDDD;
  padding: 14px 10px;
}

.biding-detail .biding-listing:last-child {
  border-bottom: none;
}

.biding-section h2 {
  font-size: 45px;
}

.cms-content p,
.cms-content ul li {
  color: #333;
}

.userrole-popup .modal-dialog,
.placebid-popup .modal-dialog,
.central-popup {
  align-items: center;
  display: flex;
  min-height: calc(100% - 3.5rem);
}

.userrole-popup .browsing-btn {
  background: #F5F5F5;
  color: #000;
  border: solid 1px #DDDDDD;
}

.contact-us .card {
  background-color: #fff;
  border: solid 1px #ddd;
  padding: 40px 40px;
  border-radius: 10px;
}

.contact-us .card form .form-control {
  height: 42px;
  font-size: 15px;
}

.contact-us .card form input::placeholder,
.contact-us .card form textarea::placeholder {
  color: #DDDDDD
}

.contact-us .card form .text-area-height {
  height: 120px;
}

.contactContnt h4 {
  font-size: 18px;
  font-weight: 600;
}

.contact-us .contact-icon i {
  font-size: 45px;
}

.contact-us form label {
  font-size: 15px;
}

.contact-us form label .required {
  color: #FF0000;
}

.inforecevier a {
  color: #2a6496;
  font-size: 16px;
  display: block;
  margin: 5px 0px 5px 0px;
  text-decoration: none;
}

.inforecevier .fa-icon {
  color: #111;
}

.sell-item-section {
  width: 65%;
  margin: 0 auto;
}

.sell-item-banner {
  width: 80%;
  margin: 0 auto;
}

.sell-item-checkicon img {
  width: 35% !important;
  object-fit: cover;
}

.tab-main-section,
.border-outline {
  border: solid 1px #ddd;
}

.tab-main-section input {
  height: 40px;
  font-size: 15px;
}

.tab-main-section input::placeholder,
.tab-main-section textarea::placeholder {
  color: #DDDDDD;
}

.tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items {
  justify-content: center;
  border-bottom: none;
}

select.form-control {
  appearance: auto;
  height: 40px;
  font-size: 15px;

}

.tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-active-item {
  background-color: transparent;
  height: auto;
}

.tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
  color: var(--medium-gray);
  font-weight: 600;
  border-radius: 5px;

}

.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item:nth-child(1) {
  padding: 8px 15px 8px 7px;
  flex: 0 0 60%;

}

.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item:nth-child(2) {
  padding: 8px 0px 8px 7px;
  flex: 0 0 40%;
}

.tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active {
  background: var(--yellow-color);
  font-weight: 600;
  padding: 12px 15px;
}

.tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items {
  justify-content: flex-start;
  border-bottom: none;
  background: #fff;
  width: 38%;
  margin: 0 auto 30px;
  text-align: center;
  border: solid 1px #DDDDDD;
  border-radius: 5px;
  padding: 7px;
}

.MuiFormControl-root .MuiInput-formControl {
  background-color: #fff;
  box-shadow: none;
}

.MuiFormControl-root .MuiInput-formControl:before {
  box-shadow: none;
}

.sell-item-heading {
  margin-top: -65px;
}

.upload-file label {
  padding: 30px;
  border: dashed 1px #808080;
  color: #808080;
  font-size: 14px;
  /* position: relative; */
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  text-align: center;
}

.sidebar-menu .ps-sidebar-container {
  background: #FAB406;
  padding: 20px 30px 100px;
  position: fixed;
  width: 250px;
}

.sidebar-menu li {
  border-bottom: solid 1px #808080;
}

.right-container .toggle-menu,
.sidebar-toggle-menu {
  display: none;
}

.sidebar-menu li>.ps-menu-button {
  padding: 6px 10px;
  margin: 3px 0;
  height: auto;
  font-size: 14px;
}

.sidebar-menu li:last-child>.ps-menu-button {
  border-color: transparent;
}

.sidebar-menu li .ps-submenu-content {
  background-color: #FED97E;
  border-radius: 10px;
  padding: 0 15px;
  margin: 4px 0;

}

.sidebar-menu li>.ps-menu-button:hover,
.sidebar-menu li.active>.ps-menu-button {
  background: #333333;
  color: #fff !important;
  border-radius: 10px;
  border-color: transparent;
}

.sidebar-menu .sub-active {
  background: #333333;
  padding: 0px 10px 10px;
  border-radius: 10px;
}

.sidebar-menu .sub-active .ps-open {
  border-bottom: none;
  color: #fff;
  height: 100% !important;
}

.sidebar-menu .sub-active a ul li>.ps-menu-button {
  color: #333333 !important;
}

.sidebar-menu span.ps-submenu-expand-icon,
.collapsed-sidebar span.ps-submenu-expand-icon {
  color: #808080;
}

.sidebar-menu span.ps-submenu-expand-icon span,
.collapsed-sidebar span.ps-submenu-expand-icon span {
  width: 8px;
  height: 8px;
  border-right: 1px solid;
  border-bottom: 1px solid;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 0;
  background-color: transparent;
}

.sidebar-menu .sub-active span.ps-submenu-expand-icon.ps-open,
.collapsed-sidebar .sub-active span.ps-submenu-expand-icon.ps-open {
  color: #808080 !important;
  -webkit-transform: rotate(-177deg);
  -moz-transform: rotate(-177deg);
  -ms-transform: rotate(-177deg);
  transform: rotate(-177deg)
}

.sidebar-menu .ps-sidebar-container::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.sidebar-menu .ps-sidebar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar-menu .ps-sidebar-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar-menu .ps-sidebar-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* collapsed sidebar start */
.collapsed-sidebar {
  width: 50%;
  background: #FAB406;
  border-radius: 10px;
}

/*  collapsed sidebar end */
.detail-heading {
  background-color: #ddd;
  border-radius: 10px 10px 0 0;
}

.profile-deatils,
.card-details {
  border: solid 1px #ddd;
  border-radius: 10px;
}

.profile-detail-content {
  border-bottom: solid 1px #DDDDDD;
}

.password i {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: #808080
}

.right-container {
  width: calc(100vw - 282px);
  padding-top: 32px;
  margin-top: 30px;
}

.profile-deatils .form-control {
  font-size: 15px;
  height: 40px;
  color: #808080;
}

.mt-30 {
  margin-top: 30px;
}

aside.sidebar-menu {
  border: none;
}

.profile-deatils input::placeholder {
  color: #808080;
}

.profile-deatils label {
  font-size: 14px;
}

.card-details table tr td {
  color: #808080;
  font-size: 15px;
  padding: 12px 10px;
}

.table th,
.table td {
  vertical-align: middle;
  font-size: 13px;
  line-height: normal;
}

.card-details tbody tr {
  border-top: solid 1px #ddd;
}

.card-details tbody tr td i {
  color: #05C002
}

.card-img {
  max-height: 200px;
  height: 200px;
}

.profile-deatils .form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
  box-shadow: none !important;
}

.profile-deatils .form-switch .form-check-input:checked {
  background-color: #FAB406;
  border-color: #FAB406;
  box-shadow: none !important;
}

.faq-search .search-icon {
  top: 50%;
  transform: translateY(-50%);
  left: 2%;
}

.comment-section {
  border: solid 1px #ddd;
  padding: 30px;
}

.user-comment {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.comment-reply-comment-wrapper {
  margin-top: 30px;
}

.comment-header {
  display: flex;
  gap: 15px;
}

.comment-author {
  font-weight: bold;
  color: #333;
}

.comment-timestamp {
  font-size: 12px;
  color: #666;
}

.reply {
  font-size: 13px;
  color: #858484;
}

.comment-text {
  font-size: 15px;
  color: #000;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 10px;
}

.comment-reply-container-wrap span {
  font-size: 13px;
  color: #878686;
  font-weight: 400;
}

.reply-header {
  display: flex;
  gap: 15px;
}

.reply-author {
  font-weight: bold;
  color: #333333
}

.reply-timestamp {
  font-weight: 600;
}

.comment-section .user-comment textarea {
  font-size: 14px;
}

.MuiTextarea-root {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.user-list {
  display: flex;
  flex-wrap: wrap;
}

.user-list div {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 16px;
  cursor: pointer;
}

.tagged-users {
  display: flex;
  flex-wrap: wrap;
}

.tagged-user {
  background-color: #e0e0e0;
  background-color: #d1ffbd;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.tagged-user span {
  margin-right: 5px;
}

.tagged-user button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.detail-slider .slick-next:before,
.detail-slider .slick-prev:before {
  opacity: .85;
  color: #fab406;
  font-size: 22px;
}

.yellow-text {
  color: #FAB406;
}

.detail-slider .slick-prev {
  left: 3px;
  z-index: 9;

}

.detail-slider .slick-next {
  right: 3px;
}

.detail-slider .slick-dots {
  display: none;
}

/* May 7 2024 */
.slider-img img {
  /* height: 430px; */
  width: 100%;
  object-fit: cover;
}

.slider-imgs {
  height: 430px
}

.slider .on-off {
  font-size: 14px;
}

.slider-imgs img {
  height: 123px;
  width: 100%;
  object-fit: cover;
}

.view-all-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  right: 0;
  text-align: center;
}

.view-all-btn button {
  border: solid 1px #fff;
  padding: 7px 34px;
  background-color: transparent;
  font-weight: 600;
  font-size: 20px;
}

.bg-overlay:before {
  position: absolute;
  content: "";
  background-color: #0000006e;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;

}

.profile-deatils .upload-file-close {
  right: 15px;
  top: -13px;
  opacity: 1;
}

.profile-deatils .upload-file-close button {
  padding: 0;
}

.profile-deatils .upload-file-close i {
  background: #FF0000;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  color: #fff;
  line-height: 24px;
  font-size: 14px;
}

.contact-top-section {
  padding: 60px 80px;
}

.contact-top-section p {
  color: #333;
}

.payment-top-section {
  padding: 60px 80px -10px;
}

.payment-button {
  margin-top: 5%;
  margin-left: 30%;
  margin-bottom: 2%;
}

.detail-slider .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000f0;
  /* Semi-transparent black background */
  backdrop-filter: blur(5px);
  /* Apply blur effect to the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.detail-slider .modal-content {
  position: unset;
  /* background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  max-width: 100%;
  max-height: 92%;
}

/* .detail-slider .modal-content .modal-body {
  overflow-y: auto;
} */

.detail-slider .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  z-index: 1000;
  /* Ensure the close button appears above the modal content */
}

.images-count-new {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  gap: 20px;
}

.modal-content img {
  max-width: 100%;
  /* Ensure images don't exceed the width of the modal content */
  max-height: 100%;
  /* Ensure images don't exceed the height of the modal content */
  height: auto;
  /* Allow images to maintain their aspect ratio */
  padding: 10px;
  border-radius: 10px;
}

.btn.common-button.sell-game-btn {
  font-size: 24px;
  padding: 13px 50px;
}

.detail-slider .close-button i {
  background: var(--yellow-color);
  border-radius: 50px;
  width: 24px;
  height: 24px;
  color: #fff;
  line-height: 24px;
  font-size: 14px;
}

.tab-main-section .btn.common-button {
  padding: 12px 100px;
}

.tab-main-section .btn.common-button.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.sell-item-section .tab-main-section {
  padding: 40px 60px;
}

.MuiTextarea-root.form_control {
  border: solid 1px #ddd;
  box-shadow: none !important;
  border-radius: 10px;
}

.form_control::before {
  box-shadow: none !important;
}

nav ul li .ps-menu-icon {
  margin-right: 0;
}

.game-category {
  background: #F2E1B5;
  font-size: 11px;
  padding: 3px 15px !important;
  color: #000 !important;
}

.reserve-item {
  background: #9acc87;
  font-size: 11px;
  padding: 3px 3px !important;
  color: #000 !important;
  /* align-content: center; */
}

.check_list input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check_list label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.check_list label:before {
  content: '';
  -webkit-appearance: none;
  border: 1px solid #DDDDDD;
  background: #fff;
  border-radius: 2px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.check_list input:checked+label::before {
  background: var(--yellow-color);
  border-color: var(--yellow-color)
}

.check_list input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

form .accordion .accordion-item {
  border: none;
}

form .accordion button.accordion-button {
  background: transparent;
  padding: 0;
  box-shadow: unset;
}

form .accordion button.accordion-button {
  color: #000 !important;
  opacity: 1;
  font-weight: 600;
  font-size: 24px;
}

form .accordion-button:not(.collapsed)::after {
  filter: brightness(0);
}

form .accordion-body {
  padding: 17px 0 0;
  border: none
}

.auction-detail-secion {
  border: solid 1px #ddd;
  padding: 30px;
  margin-top: 30px;
  border-radius: 20px;
}

.auction-detail-secion h2 {
  font-size: 45px;
  line-height: 61px;
}

.auction-detail-secion .btn {
  font-size: 16px;
  padding: 12px 15px;
  width: 243px;
  color: #fff;
}

.auction-detail-secion .btn.btn-share {
  background-color: #06BF2F;
  border: solid 1px #06BF2F !important;
}

.auction-detail-secion .btn.btn-category {
  background-color: #0075FF;
  border: solid 1px #0075FF !important;
  margin-bottom: 15px;
}

.auction-detail-secion .btn.btn-category:hover svg {
  color: #000 !important;
  transition: .2s ease-in-out;
}

.auction-detail-secion .btn:hover {
  background-color: transparent;
}

.auction-detail-secion p {
  color: #333;
  line-height: 21px;
  margin-bottom: 10px;
}

.detail-section {
  border: solid 1px #ddd;
  border-radius: 10px;
}

.detail-section .item-detail-column {
  width: 33%;
  border-radius: 10px 0 0 0;
}

.auction-detail-page h5 {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 10px;
}

.detail-section .item-detail-column.item-detail-hedaing-column {
  width: 21%;
}

.detail-section .item-detail-column.item-detail-description-column {
  width: 25%;
}

.detail-section .item-detail-column.border-left {
  border-left: solid 1px #ddd;
  border-radius: 0 !important;
}

.detail-section .item-detail-column p {
  color: #333;
  padding: 12px 25px 12px 30px;
  height: 100%;
}

.border-bottom-line {
  border-bottom: solid 1px #ddd;
}

.comment-section textarea {
  font-size: 16px;
  border-color: #ddd;
  outline: none;
}

.comment-section textarea::placeholder {
  color: #808080;
}

.custom-pagination p {
  margin-bottom: 0;
}

.MuiPaper-root {
  /* top: 81px !important; */
  border-radius: 10px !important;
  border: solid 1px #ddd;
  box-shadow: none !important;
  overflow-x: visible !important;
  overflow-y: visible !important;

}

/* .MuiPaper-root:before {
  content: '';
  position: absolute;
  top: 7px;
  left: 10%;
  margin-left: -15px;
  margin-top: -15px;
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid 10px #fff;
  border-left: solid 10px transparent;
  border-right: 10px solid transparent;
}

.MuiPaper-root:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ddd;
  top: -10px;
  content: '';
  left: 10%;
  margin-left: -15px;
  position: absolute;
} */

.notification-content {
  margin-bottom: 10px;
}

.notification-content span {
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
  color: #333;
}

.notification-content p {
  font-size: 15px;
  margin-bottom: 0;
}

.notification-content i {
  color: #333;
  position: relative;
  top: 3px;
}

.header-wrapper .navbar-nav li .nav-link {
  color: #333;
  font-weight: 600;
  padding: 0 4px;
}

.auction-bidbar {
  position: sticky;
  top: 108px;
  right: auto;
}

.auction-bidbar .bid-left-section {
  width: 100%;
  text-decoration: none;
}

.auction-bidbar .bid-right-section {
  width: 22%;
}

input[type="text"].bid-input {
  height: 68px;
  background: #FAB406;
  border-radius: 10px !important;
  font-size: 37px !important;
  font-weight: 700;
  width: 65%;
  text-align: center;
  padding-right: 6px !important;
  border: 2px solid black;
}

.placebid-popup .react-numeric-input input[type="text"] {
  height: 68px;
  background: #FAB406;
  border-radius: 10px !important;
  font-size: 37px !important;
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding-right: 30px !important;
}

.placebid-popup .react-numeric-input {
  max-width: 70%;
}

.placebid-popup .react-numeric-input b:nth-child(2),
.placebid-popup .react-numeric-input b:nth-child(3) {
  box-shadow: unset !important;
  border: 0px !important;
  background: transparent !important;
  right: 20px !important;
}

.placebid-popup .react-numeric-input b:nth-child(2) {
  bottom: 50% !important;
}

.placebid-popup .react-numeric-input b:nth-child(2) i:nth-child(2) {
  display: none;
}

.placebid-popup .react-numeric-input b:nth-child(3) {
  top: 50% !important;
  bottom: 2px;
  inset: auto;
}

.placebid-popup .react-numeric-input b:nth-child(2) i {
  border-width: 0px 1.4ex 1.4ex !important;
  border-radius: 5px;
  right: 0px !important;
  border-style: solid !important;
  border-color: transparent transparent rgba(0, 0, 0, 0.7) !important;
  left: 0px !important;
  background: transparent !important;
}

.placebid-popup .react-numeric-input b:nth-child(3) i {
  border-width: 1.4ex 1.4ex 0px !important;
  border-radius: 5px;
  right: 0 !important;
  left: 0px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent !important;
  background: transparent !important;
}

.placebid-popup .status-btn button.btn-secondary {
  background: transparent;
  color: #808080;
  border: solid 1px #808080 !important;
  font-size: 18px;
}

.video-section video {
  max-width: 100%;
}

.video-section .video-inner-section {
  flex: 0 0 50%;
}

.coin-animation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0%;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #000000ba;
}

.coin-animation .common-button:hover {
  background-color: #B48000 !important;
}

.coin-animation .coin p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.coin-animation .coin .btn.success-msg:hover {
  color: #fff !important;
}

.coin {
  height: 100%;
  width: 100%;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin::before {
  /* content: ""; */
  display: block;
  position: relative;
  height: 100%;
  width: 80%;
  /* border-radius: 50%; */
  /* background-color: #FAB406; */
  /* animation: spin 1s linear infinite; */
  background-image: url('/src/images/coin-spin.gif');
  background-size: contain;
  background-position: center;
  /* background-blend-mode: overlay; */
  background-repeat: no-repeat;
}

.spin-coin-gif {
  margin-top: -30px;
}

.seller-pofile-pic {
  font-size: 150px;
  border: 8px solid black;
  padding: 15px 15px 0px 15px;
  border-radius: 15px;

}

.mb-header-link,
.mobile-searchbar {
  display: none;
}


.top-caterogies h2 {
  text-transform: capitalize;
}

.featured-card {
  border-radius: 10px !important;
  border: 2px solid #FAB406 !important;
}

.card {
  border-radius: 10px !important;
  border: 1px solid #ddd !important;
}

.card .card-body {
  padding: 20px 5px 15px !important;
}

.featured-card .card-body {
  padding: 20px 5px 15px !important;
}

.feauture-grail {
  width: 100%;
  padding: 4px 0;
  border-radius: 0px 0px 6px 0px;
  background-color: var(--yellow-color);
  border-color: var(--yellow-color);
}

.ban_sec {
  margin-bottom: 80px;
}

.featured-auctions-sec,
.top-caterogies,
.about-conttent-sec {
  padding-bottom: 80px;
}

.search-bar input {
  border: 1px solid #ddd;
  background-color: #f4f4f4 !important;
  border-radius: 10px;
}

.search-bar input:focus {
  border-color: #ddd;
}

span.header-search-close-wrap {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 24px;
  transform: translateY(-50%);
  background-color: #f4f4f4;
}

.search-bar .dropdown-options.search-location-options {
  top: 84% !important;
  background-color: #f4f4f4 !important;
  border: 1px solid #ddd !important;
  border-top: 0px !important;
  padding-top: 14px !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.search-bar .dropdown-options.search-location-options li h6 {
  background-color: transparent !important;
  margin-bottom: 0;
}

.search-bar .dropdown-options.search-location-options li {
  padding: 13px 10px !important;
  background-color: transparent !important;
}

.search-bar .dropdown-options.search-location-options li:first-child {
  border-top: 1px solid #ddd;
}

.search-bar .dropdown-options.search-location-options li:hover {
  background-color: #fab40624 !important;
}

.caterogies-card>div {
  overflow: hidden;
  border-radius: 10px;
}

.caterogies-card img {
  transition: 0.4s;
}

.caterogies-card img:hover {
  transform: scale(1.1);
  transition: 0.4s;
}

.location-img-sec img {
  width: 20px;
}

/* animation definitions */

/* gallery section css start */

.gallery-container {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  /* 3 columns: left takes 2fr, right takes 1fr each */
  grid-template-rows: repeat(4, 1fr);
  /* 4 rows */
  gap: 5px;
  padding: 10px;
  max-height: 600px;
}

.slider-item-1 {
  grid-column: 1 / 2;
  /* First column */
  grid-row: 1 / 5;
  /* Spans all 4 rows */
  overflow: hidden;
}

.slider-item-2 {
  grid-column: 2 / 3;
  /* Second column */
  grid-row: 1;
}

.slider-item-3 {
  grid-column: 3 / 4;
  /* Third column */
  grid-row: 1;
}

.slider-item-4 {
  grid-column: 2 / 3;
  grid-row: 2;
}

.slider-item-5 {
  grid-column: 3 / 4;
  grid-row: 2;
}

.slider-item-6 {
  grid-column: 2 / 3;
  grid-row: 3;
}

.slider-item-7 {
  grid-column: 3 / 4;
  grid-row: 3;
}

.slider-item-8 {
  grid-column: 2 / 3;
  grid-row: 4;
}

.slider-item-9 {
  grid-column: 3 / 4;
  grid-row: 4;
}

.slider-item-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image-box {
  height: 147px;
  overflow: hidden;
  transition: 0.3s;
}

.image-box:hover {
  border: 3px solid #FAB406;
  transition: 0.3s;
}


.image-box img {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}

/* gallery section css end */

@keyframes spin {
  0% {
    width: var(--coin-size);
    box-shadow: 0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }

  49.999% {
    width: 0.1rem;
    box-shadow: 0.05rem 0 0 var(--side), 0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side), 0.2rem 0 0 var(--side), 0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side), 0.35rem 0 0 var(--side), 0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side), 0.5rem 0 0 var(--side), 0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side), 0.65rem 0 0 var(--side), 0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }

  50.001% {
    width: 0.1rem;
    box-shadow: -0.05rem 0 0 var(--side), -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side), -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side), -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side), -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side), -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side), -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side), -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    transform: translateX(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }

  100% {
    width: var(--coin-size);
    box-shadow: 0 0 0 var(--side-dark);
  }
}

.daily-email-section input {
  height: 43px;
}

.daily-email-section p {
  font-size: 16px;
  color: #333;
}

.dropdown-menu a:active.dropdown-item {
  background: transparent;
  color: #000;
}

/* ---06-06-2024-css-start--- */
.product-gallery-slider .image-gallery-left-nav .image-gallery-svg,
.product-gallery-slider .image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 50px;
}

.product-gallery-slider .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
.product-gallery-slider .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
  width: calc(100% - 15%);
  display: block;
}

.product-gallery-slider .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.product-gallery-slider .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  width: 15%;
  display: block;
  margin: 0;
}

.product-gallery-slider .image-gallery-thumbnail {
  width: 100%;
}

.product-gallery-slider .image-gallery-thumbnail.active,
.product-gallery-slider .image-gallery-thumbnail:focus {
  border: 4px solid #B48000;
}

.product-gallery-slider .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 125px;
  max-height: 100%;
  object-fit: cover;
}

.product-gallery-slider .image-gallery-slides img {
  height: 430px;
  object-fit: cover;
}

.product-gallery-slider .image-gallery-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}


.product-gallery-slider .image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-slider .image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
  height: 500px;
}

.detail-slider .image-gallery-left-nav .image-gallery-svg,
.detail-slider .image-gallery-right-nav .image-gallery-svg {
  height: 70px;
  width: 70px;
}

.favourite {
  cursor: pointer;
}

.faq-search input {
  padding-left: 6%;
}

/* ---06-06-2024-css-end--- */

/* ---10-06-2024-css-start--- */
.header-wrapper span.navbar-brand img {
  height: auto;
  image-rendering: pixelated;
}

.header-wrapper span.navbar-brand a {
  display: inline-block;
}

.header-wrapper span.navbar-brand {
  width: 170px;
}

.header-wrapper .navbar-nav li .nav-link.special-hover:hover {
  color: #fab406 !important;
}

/* .nav-click {
  color: #fab406 !important;
}; */

.nav-item .notify-link:hover sup {
  color: #1f1f1f !important;
}

/* ---10-06-2024-css-end--- */

/* 27-06-2024 start */

/* 27-06-2024 end */


.faq-search .form-control::placeholder {
  text-align: center;
  /* Center the placeholder text */
}

.sidebar-menu-section {
  width: unset !important;
  min-width: 250px;
}

.MuiInputBase-input {
  background-color: #fab406 !important;
  border-radius: 7px !important;
}

.image-gallery-icon:hover {
  color: #FAB406 !important;
}

.image-gallery-icon:focus {
  outline: none;
}

.tab-main-section .MuiFormControl-root.MuiFormControl-vertical {
  position: unset !important;
}

.search-game-section {
  margin-top: 40px;
}

.daily-email-section img {
  width: 100px;
}

.btn-green {
  background-color: #9acc87 !important;
  color: #000000 !important;
}

.heart-png img {
  width: 18px;
}

.about-us-level {
  padding: 30px;
  border: 1px solid #DDDDDD;
  background-color: #F5F5F5;
  border-radius: 10px;
}

.level-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.level-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.dollar-fixed {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-50%);
  font-size: 37px !important;
  font-weight: 700 !important;
  color: #000;
  z-index: 999;
}

.success-notice {
  background-color: #c2edcbc4;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #8bb594;
}

.success-notice i::before,
.success-notice h5,
.success-notice p {
  color: #09531a;

}

.success-notice i::before {
  font-size: 24px;
}

.slick-slide {
  width: fit-content !important;
  padding-right: 20px;
}

.filter-slider {
  position: relative;
  padding-right: 80px !important;
}

.slick-list {
  margin: 0 25px !important;
}

.filter-slider p {
  margin-bottom: 0;
  color: #333;
}

.filter-slider .slick-prev {
  left: -2px !important;
  z-index: 50;
}

.filter-slider .slick-next {
  right: -2px !important;
  z-index: 50;
}

.filter-slider .slick-prev::before,
.filter-slider .slick-next::before {
  font-family: 'bootstrap-icons';
  color: #000;
  font-weight: 700;
}

.filter-slider .slick-prev::before {
  content: "\F284";
}

.filter-slider .slick-next::before {
  content: "\F285";
}

.just-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  padding: 0 15px 0 24px;
  z-index: 10;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  display: none;
}

.just-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  /* padding: 0 24px 0 15px; */
  z-index: 10;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.rating-image-box {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #000;
}

.rating-image-box img {
  width: 100%;
  height: 100% !important;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  padding: 0px;
}

.semibold-black {
  color: #333;
  font-weight: 600;
}

.mobile-manage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slick-slider p {
  padding: 8px 16px;
}

.slick-slider .category-box {
  padding: 8px 16px;
}

.nav-link .notificatin-value {
  position: absolute;
  min-width: 18px;
  min-height: 18px;
  top: -14px;
  right: -8px;
  background-color: #fab406;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 22px;
  height: 22px;
}

.video-thumbnail img {
  width: 75px;
}

.special-margin {
  margin-right: 8px;
}

.video-thumbnail .rounded-circle {
  top: -10px;
  right: -1px;
  background-color: #ef1010;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.video-thumbnail .rounded-circle:hover {
  background-color: #c50505;
  color: #fff;
}

.location-new-icon {
  image-rendering: pixelated;
}

.media-player-section {
  width: 100%;
}

.media-player-section iframe,
.video-img-section video,
.video-img-section img {
  width: 100%;
}

.view-all-notification {
  width: 80% !important;
}

.new-link-hover:hover {
  color: #000 !important;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #FAB406;
}

input:focus+.slider {
  box-shadow: 0 0 1px #FAB406;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.drop-downlist button img {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 1px solid #fff;
  object-fit: cover;
  object-position: top;
}

.drop-downlist .dropdown-toggle {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.drop-downlist .dropdown-toggle i::before {
  color: #000;
}

/* sell an item checkbox */

.slider.round {
  border-radius: 34px;
}

.mssg-text {
  font-size: 22px;
  font-family: 'Times New Roman', Times, serif;
}

.create-img {
  height: 26rem;
  width: 400px;
}

.category-box {
  background-color: #FAB406;
  border: solid 1px #DDDDDD;
  color: #000;
  font-size: var(--small-font);
  font-weight: 400;
  display: block;
  line-height: normal;
  border-radius: var(--bs-border-radius-pill) !important;
  padding: 2px 6px 2px 6px;
}


.sell-item-video {
  padding: 30px;
  border: dashed 1px #808080;
  color: #808080;
  font-size: 14px;
  position: relative;
  text-align: center;
  background-color: var(--bs-body-bg);
  border-radius: 2%;
}

.sell-inner-video {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
}

/* .gallary-image {
  height: 20%;
  width: 20%;
} */

.images-modal-content {
  border-radius: 8px;
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.images-container {
  max-height: 85vh;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 0 50px;
}

.images-container img {
  height: auto !important;
  object-fit: cover;
  width: 100%;
}

.slider-item-9::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000006c;
  z-index: 10;
}

.show-images-text {
  z-index: 15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}


.images-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.images-container::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FAB406;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-bell {
  margin-right: 20px !important;
}

.rep-profile-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 8px solid #FAB406;
  padding: 3px;
}

.rep-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-position: center;
}

.detail-progress {
  gap: 20px;
}

.search-icon-store {
  width: 20px;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.featured-card .card-body .card-text.two-line-text .ql-editor {
  overflow: unset;
  padding-left: 0px;
  padding-right: 0px;
}

.bid-right-section button.btn.common-button {
  padding: 13.5px 13px;
}

/* --------17-03-2025-css-start------ */
.photography-section-wrap h1 {
  position: relative;
  display: inline-block;
}

.photography-section-wrap h1::before {
  content: "";
  background-color: #fab406;
  height: 8px;
  position: absolute;
  width: 100%;
  border-radius: 5px;
  bottom: -20px;
}

.professional-photos-section .professional-new-photos-label {
  margin-right: 10px;
  margin-bottom: 3px;
  padding: 6px 6px 5px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: #fab406;
  border-radius: 6px;
  display: inline-block;
  vertical-align: middle;
}

.professional-photos-section .one-hr-shoot-col-wrap,
.professional-photos-section .two-hr-shoot-col-wrap {
  padding: 35px 20px;
  border: 1px solid #fab406;
  border-radius: 12px;
  background: linear-gradient(148deg, #fab4061f, transparent);
  position: relative;
  height: 100%;
  box-shadow: 1px 1px 10px #00000029;
}

.best-value-img-wrap {
  position: absolute;
  width: 22%;
  top: -30px;
  right: -30px;
}

.snappr-works-card-wrap .steps-num-wrap {
  height: 30px;
  width: 30px;
  background-color: #fab406;
  color: #000;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.snappr-works-img-wrap {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
}

.snappr-works-content-wrap {
  padding: 6px 10px 20px 10px;
}

.snappr-works-card-wrap {
  border: 1px solid #fab406;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #00000029;
}

.download-photo-guide-wrap a {
  display: inline-flex;
  gap: 10px;
  border: 1px solid #B48000;
  padding: 10px 20px;
  text-decoration: none;
  align-items: center;
  border-radius: 10px;
  color: #000;
}

.snappr-works-img-wrap img {
  width: 100%;
}

.auction-detail-secion p {
  word-break: break-all;
}

.sell-items-img-upload-wrap.video-thumbnail {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.sell-items-img-upload-wrap.video-thumbnail img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  margin-left: 0px !important;
}

/* --------17-03-2025-css-end------ */

/* -----20-03-2025-css-start---- */
.collect-brandon-profile-grid {
  display: grid;
  grid-template-columns: 8% 1fr;
  gap: 16px;
  align-items: center;
  margin-bottom: 10px;
}

.collect-brandon-profile-grid .collect-brandon-profile-wrap {
  height: auto;
  width: 100%;
}

.payment-top-img-wrap {
  width: 75%;
}

/* -----20-03-2025-css-end---- */



/* media-query-start-here */
@media (min-width: 769px) {
  .row.detail-page-listing {
    margin-top: -54px;
  }

  .navbar-expand-md .navbar-collapse#home-auctions-navbar-nav {
    height: 100%;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 990px !important;
  }

}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px !important;
  }
}

@media (min-width: 1300px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1295px !important;
  }

  .search-bar input {
    width: 370px;
  }

  .bids-detail-section p {
    font-size: 18px;
  }

  .auction-bidbar .bid-right-section .common-button {
    font-size: 18px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1388px !important;
  }

  .search-bar input {
    width: 408px;
  }

  .header-wrapper .navbar-nav li .nav-link {
    padding: 0 7px;
    font-size: 16px;
  }

  .bids-detail-section p {
    font-size: 16px;
  }

  button.common-button,
  .btn.common-button,
  #dropdown-basic-button,
  .quote-section .btn.common-button {
    font-size: 16px;
    padding: 12px 30px;
  }

  p,
  li {
    font-size: 16px;
  }

  .bids-detail-section {
    padding: 14.4px 14px;

  }
}

@media (min-width: 1480px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1455px !important;
  }

  .search-bar input {
    width: 466px;
  }

  .bids-detail-section {
    padding: 14.4px 21px;
  }
}

@media (min-width: 1024px) {
  .custom-progress {
    width: 50% !important;
  }
}

@media(max-width:1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px;
  }

  button.common-button {
    padding: 9px 16px;
    font-size: 13px;
  }

  .sell-item-section {
    width: 77%;
  }

  .header-wrapper .navbar-nav li .nav-link {
    font-size: 13px;
  }

  .header-wrapper button.common-button {
    padding: 10px 13px;
    font-size: 14px;
  }

  .search-bar input {
    font-size: 13px;
    height: 40px;
    width: 243px;
  }

  .header-wrapper span.navbar-brand {
    width: 120px;
  }

  .bids-detail-section {
    padding: 14px 8px;
  }

}

@media screen and (max-width:1024px) {

  .featured-auctions-sec,
  .top-caterogies,
  .about-conttent-sec {
    padding-bottom: 60px;
  }

  .custom-pagination .MuiInputBase-root {
    margin-right: 0;
  }

  .ban_sec {
    margin-bottom: 60px;
    margin-top: 5px;
  }

  .newsletter-section .subscriber-email {
    width: 65%;
  }

  .newsletter-section .subscriber-button {
    width: max-content;
  }

  .newsletter-section p {
    font-size: 16px;
  }

  .right-container {
    width: calc(100vw - 282px);
    padding-top: 32px;
    margin-top: 20px;
    padding-left: 10px;
  }

  .header-wrapper button.common-button {
    margin-right: 0px !important;
  }

  #dropdown-basic-button {
    padding: 9px 15px !important;
  }

  .header-wrapper span.navbar-brand img {
    image-rendering: pixelated;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
  }

  .placebid-popup .react-numeric-input b:nth-child(3) {
    top: 50% !important;
    bottom: 2px;
    inset: 20px 20px 2px auto !important;
  }

  .auction-bidbar {
    top: 90px;
  }

  .drop-downlist button img {
    height: 23px;
    width: 23px;
  }
}

@media(max-width:992px) {

  button.common-button,
  .btn.common-button,
  #dropdown-basic-button,
  .bid-right-section button.btn.common-button {
    padding: 10px 14px;
  }

  .sign-in-btn {
    padding-right: 15px;
  }

  .detail-slider .image-gallery-left-nav .image-gallery-svg,
  .detail-slider .image-gallery-right-nav .image-gallery-svg {
    display: none;
  }

  .sell-item-btn {
    margin-top: 16px;
  }

  .navbar-collapse .nav-item {
    width: 50%;
  }

  .images-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .banner-buttons .common-button:active {
    background-color: #FAB406 !important;
    border: 1px solid #fab406 !important;
  }

  .header-icons-section {
    gap: 16px;
  }

  .mb-header-link {
    margin-right: -20px;
  }

  .banner-buttons .common-button:hover {
    background-color: #FAB406 !important;
    border: 1px solid #B48000 !important;
  }

  .dropdown-image-profile {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-right: 8px;
  }

  .dropdown-image-profile img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
  }

  .newsletter-section .subscriber-button {
    width: 43%;
  }

  .dropdown-image-profile.mobile-profile {
    width: 27px;
    height: 27px;
    border: 1px solid #fab406;
    border-radius: 50%;
    padding: 1px;
    margin-right: 15px;
  }

  .search-bar input {
    width: 100%;
  }

  .right-container .toggle-menu {
    display: none !important;
  }

  .caterogies-card img {
    aspect-ratio: 1;
  }

  .special-edit {
    font-size: 20px !important;
    padding: 4px 10px !important;
  }

  .about-conttent-sec .left-scetion {
    padding-right: 0px !important;
  }

  h2 {
    font-size: 36px !important;
  }

  .newsletter-section .subscriber-button {
    width: 100%;
    margin-top: 16px;
  }

  .newsletter-section .subscriber-email {
    width: 100%;
  }

  .footer-mid h5 {
    font-size: 16px;
  }

  .left-scetion h2 {
    font-size: 28px !important;
  }

  .product-gallery-slider .image-gallery-slides img {
    height: 300px;
  }

  .product-gallery-slider .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 95px;
  }

  /* .mobile-device-search-bar.hide-search-bar {
    position: absolute;
    width: 107%;
    height: 100vh;
    left: -24px;
    top: 85px;
    right: 0;
    display: inline-block;
}

.mobile-device-search-bar.hide-search-bar input[type="search"] {
  width: 100%;
  position: relative;
  padding: 14px 15px 14px 40px;
} */

  .mobile-device-search-bar.search-bar input[type="search"] {
    background-color: #f4f4f4 !important;
    width: 100%;
    position: relative;
    border: 1px solid #ddd;
    padding: 14px 15px 14px 40px;
    height: 100% !important;
  }

  .search-bar .dropdown-options.search-location-options {
    top: 100px !important;
    width: 90% !important;
    margin: 0 auto !important;
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .mobile-device-search-bar.search-bar {
    position: absolute;
    width: 107%;
    background-color: #fff;
    height: 100vh;
    left: -24px;
    top: 0;
    right: 0;
    display: inline-block;
    z-index: 2;
  }

  .mobile-search-heading {
    padding-left: 26px;
  }

  .mobile-search-heading h3::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
    width: 9px;
    height: 18px;
    background-color: #FAB406;
    border-radius: 0 5px 5px 0;
  }

  .mobile-device-search-bar.search-bar .mobile-searchbar-inner-div {
    width: 90%;
    margin: 25px auto 0px auto !important;
  }

  .mobile-searchbar-inner-div span.search-icon {
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    z-index: 1;
  }

  .mobile-searchbar-inner-div input {
    padding-left: 40px;
  }

  .mobile-searchbar-inner-div span.search-icon i {
    font-size: 14px;
  }

  .mobile-searchbar-inner-div span.search-close-icon {
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    display: none;
  }

  .search-bar .dropdown-options.search-location-options li {
    padding: 13px 10px !important;
    background-color: transparent !important;
  }

  .placebid-popup .modal-body .w-75 {
    width: 100% !important;
  }

  .sidebar-menu-section {
    width: unset !important;
    min-width: unset !important;
  }

  .search-game-section {
    margin-top: 15px;
  }

  .dollar-fixed {
    left: 31%;
    font-size: 33px !important;
  }

  .sidebar-menu .ps-sidebar-container {
    width: 100%;
    margin-top: 20px;
    padding: 40px 20px;
  }

  .sidebar-menu-section .ps-menu-root {
    width: 80%;
  }

  .sidebar-menu li {
    border: none;
  }

  .sidebar-menu .sidebar-toggle-menu i::before {
    font-size: 24px;
  }

  /* gallery css */

  .detail-slider {
    overflow-x: scroll;
    width: 100%;
  }

  .detail-slider .slider-item-1 {
    height: 250px;
  }

  .image-box {
    height: 123px;
    width: 170px;
  }

  .gallery-container {
    display: grid;
    grid-template-columns: 85% repeat(4, 1fr);
    /* First column takes 60%, others are equally spaced */
    grid-template-rows: repeat(2, 1fr);
    /* Two rows */
    gap: 5px;
    padding: 10px;
  }

  .slider-item-1 {
    grid-column: 1 / 2;
    /* Stays in the first column */
    grid-row: 1 / 3;
    /* Spans two rows */
    overflow: hidden;
  }

  .slider-item-2 {
    grid-column: 2 / 3;
    /* Second column */
    grid-row: 1;
  }

  .slider-item-3 {
    grid-column: 3 / 4;
    /* Third column */
    grid-row: 1;
  }

  .slider-item-4 {
    grid-column: 4 / 5;
    /* Fourth column */
    grid-row: 1;
  }

  .slider-item-5 {
    grid-column: 5 / 6;
    /* Fifth column */
    grid-row: 1;
  }

  .slider-item-6 {
    grid-column: 2 / 3;
    /* Second column */
    grid-row: 2;
  }

  .slider-item-7 {
    grid-column: 3 / 4;
    /* Third column */
    grid-row: 2;
  }

  .slider-item-8 {
    grid-column: 4 / 5;
    /* Fourth column */
    grid-row: 2;
  }

  .slider-item-9 {
    grid-column: 5 / 6;
    /* Fifth column */
    grid-row: 2;
  }

  .mobile-searchbar {
    padding-right: 12px;
  }

  .mobile-searchbar.search-bar {
    width: 25px;
  }

  .search-bar span.search-icon {
    top: 50%;
    left: 10px;
  }

  .sell-items-img-upload-wrap.video-thumbnail {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
}

@media(max-width:991px) {
  h2 {
    font-size: 28px !important;
  }

  .header-wrapper .navbar-nav li .nav-link {
    padding: 8px 4px;
  }

  .search-bar input {
    display: inline-block;
    width: 17px;
    background: transparent !important;
    border: none;
    padding-left: 22px;
  }

  .header-wrapper span.navbar-brand {
    width: 150px;
    margin-right: 20px;
  }

  .header-wrapper span.navbar-brand img {
    aspect-ratio: unset;
    width: 100%;
    height: unset;
  }

  button.navbar-toggler {
    padding: 0;
    border: none;
  }

  .mb-header-link {
    padding-right: 0;
  }

  .mobile-searchbar,
  .mb-header-link {
    display: block;
  }

  .mb-header-link #dropdown-basic-button {
    padding: 6px 14px !important;
  }

  .desktop-searchbar,
  .desktop-menu {
    display: none;
  }

  ul.navbar-nav {
    align-items: start !important;
  }

  .mb-header-link ul.navbar-nav {
    flex-direction: row;
    align-items: center !important;
  }

  .contact-top-section {
    padding: 40px 25px 30px;
  }

  .contact-us .card {
    padding: 30px 30px;
  }

  .sell-item-banner {
    width: 95%;
  }

  .sell-item-section {
    width: 85%;
  }

  .sell-item-section .tab-main-section {
    padding: 35px 40px;
  }

  .tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items {
    width: 57%;
  }

  .drop-downlist .dropdown-menu {
    position: absolute !important;
    top: 49px !important;
  }

  .right-container .toggle-menu {
    display: block;
    position: absolute;
    top: 5px;
  }

  .sidebar-toggle-menu {
    display: block;
    position: absolute;
    right: 13px;
    top: 13px;
  }

  .sidebar-menu.sidebar-open-menu .ps-sidebar-container {
    padding: 15px 15px 80px;
    width: 170px;
    display: none;
  }

  aside.sidebar-menu {
    min-width: 0px !important;
    width: 0px !important;
  }

  .right-container {
    width: 100%;
    padding-left: 0;
  }

  .sidebar-menu li .ps-submenu-content {
    inset: unset !important;
    transform: unset !important;
  }

  .sidebar-menu span.ps-submenu-expand-icon,
  .collapsed-sidebar span.ps-submenu-expand-icon {
    right: 15px;
    transform: none;
    webkit-transform: none;
    -moz-transform: none;
    top: 9px;
  }


}

@media(max-width:767px) {
  .ban_sec {
    margin-top: -5px;
  }

  .ban_text h1 {
    font-size: 30px;
    line-height: 38px;
  }

  .auth-forms {
    padding: 35px 25px;
  }

  .login-btns {
    top: -46px;
    width: 71%;
  }

  .contact-us .card {
    padding: 30px 15px;
  }

  .sell-item-section {
    width: 90%;
  }

  .newsletter-section input {
    padding: 13px 5px;
  }

  .footer-top ul li {
    font-weight: 500;
    margin-right: 0px;
  }

  .footer-top ul {
    justify-content: space-between !important;
  }

  .footer-mid .row.pt-4 {
    padding-top: 0px !important;
  }

  .auction-detail-secion .col-lg-3.col-md-3.col-12.text-end.ps-md-0 {
    text-align: left !important;
    margin-top: 20px;
  }

  .auction-detail-secion h2 {
    font-size: 33px;
    line-height: 40px;
  }

  .auction-bidbar {
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 15 !important;
  }

  .auction-bidbar .bid-left-section {
    width: 66%;
  }

  .auction-bidbar .bid-right-section {
    width: 26%;
  }

  .detail-section .item-detail-column {
    width: 56%;
    border-bottom: solid 1px #ddd;
  }

  .detail-section .d-flex:nth-child(2) .item-detail-column:nth-child(3),
  .detail-section .d-flex:nth-child(2) .item-detail-column:nth-child(4) {
    border-bottom: none;
  }

  .detail-section .item-detail-column.item-detail-description-column {
    width: 56%;

  }

  .detail-section .border-bottom-line {
    border-bottom: none;
  }

  .detail-section .item-detail-column.item-detail-hedaing-column {
    width: 44%;
    border-left: 0;

  }

  .biding-detail {
    margin-top: 20px;
  }

  .mb-view {
    display: none;
  }

  .ended-detail-section .mb-view {
    display: block;
  }

  .ended-detail-section .mb-view p {
    display: flex;
  }

  .ended-detail-section .mb-view p i {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: -10px;
  }

  .bid-left-section:has(.ended-detail-section) .bids-detail-section .mb-view {
    display: block;
  }

  .auction-detail-secion {
    padding: 18px;
  }

  .about-conttent-sec .col-md-6.col-lg-7 {
    margin-bottom: 16px;
  }

  .featured-auctions-sec,
  .top-caterogies,
  .about-conttent-sec {
    padding-bottom: 40px;
  }

  .ban_sec {
    margin-bottom: 40px;
  }

  .daily-email-popup .modal-content {
    /* height: 100%; */
    /* border: none; */
    margin: 0 10px;
    /* border-radius: 0; */
  }

  .daily-email-popup.modal-dialog {
    height: 100%;
    margin: 0;
  }

  .contact-top-section {
    padding: 30px 10px;
  }

  .slider-imgs {
    height: auto;
  }

  .sell-item-heading {
    margin-top: 10px;
  }

  .sell-item-section .tab-main-section {
    padding: 30px 25px;
    margin-top: 10px;
  }

  .tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items {
    width: 86%;
  }

  #dropdown-basic-button .MuiAvatar-root {
    height: 22px;
    width: 22px;
  }

  .product-gallery-slider .image-gallery-content {
    gap: 10px;
    flex-direction: column;
  }

  .product-gallery-slider .image-gallery-thumbnails .image-gallery-thumbnails-container {
    display: block;
  }

  .product-gallery-slider .image-gallery-thumbnail {
    width: 33%;
  }

  .auction-detail-secion .btn {
    width: 100%;
  }

  .header-wrapper span.navbar-brand {
    width: 150px;
  }

  .placebid-popup .status-btn {
    margin-bottom: 16px;
  }

  .placebid-popup .react-numeric-input input[type="text"] {
    font-size: 28px !important;
  }

  .placebid-popup .react-numeric-input b:nth-child(3) i {
    top: 30% !important;
  }

  .bids-detail-section {
    padding: 14px 14px;
  }

  .delivery-status-popup-wrapper .modal-dialog.modal-lg .modal-content {
    width: 100% !important;
  }

  .professional-photos-section .one-hr-shoot-col-wrap {
    margin-bottom: 50px;
    height: auto;
  }

  .snappr-works-card-wrap {
    margin-bottom: 30px;
  }

  .auction-bidbar .bids-timing-detail-sec {
    margin-top: 10px;
  }

  .mob-auction-dropdown-wrap {
    background-color: #fab406;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
    margin-top: 13px;
    position: absolute;
    z-index: 99;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 1px 10px #00000040;
    min-width: max-content;
  }

  .mob-auction-dropdown-wrap li a {
    color: #000;
  }

  .mob-auction-dropdown-wrap li svg {
    fill: #000;
  }

  .mob-auction-dropdown-wrap li {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .payment-top-img-wrap {
    margin: 30px auto 0 auto;
  }

  .card-details table.table {
    white-space: nowrap;
  }

  .bid-status-btn-group button {
    font-size: 13px;
  }
}

@media screen and (max-width:576px) {
  .header-wrapper span.navbar-brand {
    width: 150px;
    margin-right: 0;
  }

  .detail-slider {
    margin-top: 50px;
  }

  .rep-profile-img {
    width: 120px;
    height: 120px;
    border-width: 4px;
  }

  .detail-progress {
    gap: 10px;
  }

  .progress-bar-mob {
    width: 60% !important;
  }

  .special-margin {
    margin-right: 14px;
  }

  .nav-link .notificatin-value {
    top: -4px;
  }

  .images-container {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .filter-slider {
    padding-right: 85px;
  }

  .view-all-notification {
    width: 100% !important;
  }

  .bids-detail-section p,
  .ended-bid-day-detail-section p {
    font-size: 12px !important;
  }

  .navbar-collapse .nav-item {
    width: 100%;
  }

  .filter-slider .slick-prev::before,
  .filter-slider .slick-next::before {
    font-size: 14px;
  }

  .just-left {
    display: none;
    /* font-size: 0 !important;
  width: 50px; */
  }

  .slick-slide {
    padding-right: 5px;
  }

  .mobile-device-search-bar.hide-search-bar {
    position: absolute;
    width: 105%;
    height: 100vh;
    left: -9px;
    top: 95px;
    right: 0;
    display: inline-block;
  }

  .mobile-device-search-bar.search-bar {
    position: absolute;
    width: 105%;
    background-color: #fff;
    height: 100vh;
    left: -9px;
    top: 0;
    right: 0;
    display: inline-block;
  }

  .nav-link .notificatin-value {
    width: 22px;
    height: 22px;
    top: -2px;
    right: -4px;
  }

  .mobile-searchbar-inner-div input {
    padding-left: 40px;
  }

  .quote-section {
    padding: 20px;
  }

  .quot-logo-main {
    text-align: center;
  }

  .quot-logo-main img {
    width: 70%;
  }

  .MuiTypography-body1.css-17ffvgn-MuiTypography-root {
    width: 100% !important;
  }

  .mb-header-link #dropdown-basic-button {
    padding: 6px 7px !important;
  }

  .mobile-manage {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .search-bar input {
    padding-left: 8px;
  }

  .about-us-image {
    width: 350px;
  }

  .dollar-fixed {
    left: 32%;
    font-size: 26px !important;
  }

  .items-btn-section {
    gap: 15px;
    flex-direction: column-reverse;
  }

  .items-btn-section button {
    width: 100%;
  }

  .auction-bidbar .bid-left-section {
    width: 69%;
  }

  .bid-left-section:has(.ended-detail-section) {
    width: 95%;
  }

  .auction-bidbar .bid-right-section {
    width: 23%;
  }

  .bid-right-section button.btn.common-button {
    padding: 10px;
  }

  form.location-form {
    padding: 40px 20px;
  }

  .notification-header-popup {
    width: 100% !important;
  }

  .bids-detail-section p span:first-child,
  .ended-bid-day-detail-section p span:first-child {
    font-size: 12px;
  }

  .bids-detail-section {
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .sell-item-checkicon img {
    width: 45% !important;
  }

  .featured-card .card-body {
    padding: 20px 15px 15px !important;
  }

  .comment-header,
  .reply-header {
    gap: 8px;
    /* flex-wrap: wrap; */
    /* display: grid; */
  }

  .comment-author-profile-wrap img {
    width: 100% !important;
    height: 100% !important;
  }

  .comment-timestamp {
    font-size: 10px;
  }

  .user-comment {
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  .gap-4.comment-reply-container-wrap {
    gap: 18px !important;
    flex-wrap: wrap;
    row-gap: 10px !important;
  }

  .comment-reply-comment-wrapper {
    margin-top: 20px;
  }

  .best-value-img-wrap {
    right: 0;
  }

  .collect-brandon-profile-grid {
    grid-template-columns: 12% 1fr;
    gap: 10px;
  }

  .sell-items-img-upload-wrap.video-thumbnail {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 16px;
  }

  span.header-search-close-wrap {
    right: 20px;
  }

  .payment-top-img-wrap {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .mb-header-link {
    padding-right: 0;
  }

  .slick-slider p {
    padding: 8px;
  }

  .header-wrapper span.navbar-brand {
    width: 125px !important;
  }

  .bids-detail-section p,
  .ended-bid-day-detail-section p {
    font-size: 10px !important;
  }

  .bids-detail-section p span:first-child,
  .ended-bid-day-detail-section p span:first-child {
    font-size: 10px;
  }


  .bid-right-section button.btn.common-button {
    padding: 11px 10px;
    font-size: 12px;
  }

  .auction-bidbar .bids-timing-detail-sec {
    margin-top: 0px;
  }
}

@media screen and (max-width:375px) {

  .comment-header .reply-author,
  .reply-header .reply-author {
    flex-wrap: wrap;
    /* gap: 0px !important; */
  }
}

@media (max-width:360px) {
  .footer-top ul li {
    margin-right: 16px;
  }

  .tab-main-section .rb-tabs .rb-tabs-header .rb-tabs-items {
    width: 86%;
  }

  .header-wrapper span.navbar-brand {
    width: 130px !important;
    margin-right: 0 !important;
  }

}

.zoomable-image:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
}